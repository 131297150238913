import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { GlobalFilterComponent } from '../dashboard/global-filter/global-filter.component';
import { Router, RouterLinkActive } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';
import { ApiUrls } from 'src/app/core/constants/apiUrl.constants';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent {
  userDetail: any; // Stores details of the current user
  isGallonView: boolean = false; // Flag to toggle between gallon and liters view
  menus: any[]; // Array of menu items for the admin layout
  isMobile: boolean = false;
  sidenavOpened: boolean = true;
  userDisplayName: string = '';
  constructor(
    private dialogService: DialogService, // Service for managing dialog components
    private auth: AuthService, // Authentication service for user details
    private router: Router, // Router service for navigation
    private sharedData: SharedDataService, // Service for shared data between components
    private dataService: DataService, // Service for API data interactions
    private breakpointObserver: BreakpointObserver,
  ) {
    
  
    this.userDetail = this.auth.getUserDetail();
// Define the menu items with visibility based on user roles
    this.menus = [
    //  { name: 'Dashboard', iconName: 'icon-Dashboard', link: '/admin/dashboard', isShow: false },
      { name: 'Dashboard', iconName: 'icon-Dashboard', link: '/admin/dashboard', isShow: true },
      { name: 'Active Alerts', iconName: 'icon-Active-Alerts', link: '/admin/activity-alerts', isShow: true },
      { name: 'Logs', iconName: 'icon-Logs', link: '/admin/logs', isShow: true },
      { name: 'Pools', iconName: 'icon-Pools', link: '/admin/pools', isShow: true },
      { name: 'Systems', iconName: 'icon-Systems', link: '/admin/system', isShow: true },
      { name: 'New Systems', iconName: 'icon-Systems', link: '/admin/new-system', isShow: false },
      { name: 'Segments', iconName: 'icon-Segments', link: '/admin/manage-system-segment', isShow: this.userDetail.is_admin },
      { name: 'Action Campaign', iconName: 'icon-Action-Tasks', link: '/admin/action-center', isShow: this.userDetail.is_admin },
      { name: 'Action Types', iconName: 'icon-Action-Types', link: '/admin/action-type', isShow: this.userDetail.is_admin },
      // { name: 'Action Task Results', iconName: 'icon-Action-Types', link: '/admin/action-task-result', isShow: true },
      // { name: 'Action Global Report', iconName: 'icon-Action-Types', link: '/admin/action-global-report', isShow: true },
      { name: 'Customers', iconName: 'icon-Customers', link: '/admin/customers', isShow: true },
      { name: 'Users', iconName: 'icon-Users', link: '/admin/users', isShow: this.userDetail.is_admin },
      { name: 'Settings', iconName: 'icon-Settings', link: '/admin/settings', isShow: this.userDetail.is_admin }
    ];
// Subscribe to shared data changes to update user details when profile is updated
    this.sharedData.changeEmitted$.subscribe(changeText => {
      if (changeText === 'profile_updated') {
        this.userDetail = this.auth.getUserDetail();
      }
    });
  }
 /**
   * Opens the global filter dialog.
   */
  openFilter() {
    this.dialogService.openDialogComponent(GlobalFilterComponent, { class: ['custom-dialog'] }, '880px');
  }
/**
   * Logs out the current user by calling the AuthService's logout method.
   */
  logout() {
    this.auth.logout();
  }
  /**
   * Checks if the provided link is the currently active route.
   * @param link The link to check against the current route
   * @returns True if the link is active, otherwise false
   */
  isActive(link: string): boolean {
    return this.router.isActive(link, true);
  }
   /**
   * Handles the change event of the toggle switch.
   * @param checked Boolean value indicating if the toggle is checked
   */

  toggleChanged(checked: boolean) {
    this.isGallonView = checked;
    this.updateMetricsView(checked)
  }
 /**
   * Updates the metrics view setting on the server and refreshes user details.
   * @param checked Boolean value indicating if the toggle is checked
   */
  updateMetricsView(checked: boolean) {
    const data = {
      'metrics': checked == true ? 'gallon' : "liters"
    };
    this.dataService.put({
      url: `${ApiUrls.Admin_Settings}`,
      data: data,
      isLoader: true
    }).subscribe((response: any) => {
      console.log(response)
      if (response && response.result) {
        console.log(response.result)
        console.log(this.userDetail)
        this.userDetail.metrics = data['metrics']
        let userDetails = this.sharedData.getAttribute('userDetails');
        userDetails.user_details = { ...userDetails?.user_details, ...this.userDetail };
        this.sharedData.setAttribute('userDetails', userDetails);
        window.location.reload();
      }
    });
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        this.sidenavOpened = !this.isMobile; // Open by default on desktop, closed on mobile
        this.updateUserDisplayName();
      });
      this.updateUserDisplayName();
  }

  updateUserDisplayName() {
    if (this.isMobile) {
      this.userDisplayName = this.getInitials(this.userDetail.first_name, this.userDetail.last_name );
    } else {
      this.userDisplayName = this.userDetail.fullName;
    }
  }

  getInitials(firstName?: string, lastName?: string): string {
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`.trim(); // Ensures initials are properly formatted
  }
    
  toggleSidenav(sidenav: any) {
    sidenav.toggle();
  }

}
