<div class="user-form-container">
  <form [formGroup]="form" (ngSubmit)="form.valid && userRegister(form.value)">
    <div
      class="my-profile"
      [ngClass]="
        uploadProgress != -1 && uploadProgress != 100 ? 'blur-effect' : ''
      "
    >
      <div class="circle">
        <img
          class="profile-pic"
          (click)="profileImgUrl && imagePreview(profileImgUrl)"
          [src]="
            profileImgUrl || './../../../../assets/images/dummy-profile.jpeg'
          "
        />
      </div>
      <div class="p-image">
        <mat-icon (click)="file.click()"> camera_alt</mat-icon>
        <input
          class="file-upload"
          type="file"
          accept="image/*"
          #file
          (change)="readUrl($event)"
        />
      </div>
    </div>

    <mat-form-field class="full-width">
      <mat-label>Title</mat-label>
      <mat-select disableRipple formControlName="title">
        <mat-option
          [value]="item.value"
          *ngFor="let item of applicationConstant?.title; index as i"
        >
          {{ item?.display_value }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls['title'].hasError('required')">
        Title is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width display-block">
      <mat-label>First Name </mat-label>
      <input matInput formControlName="first_name" />
      <mat-error *ngIf="form.controls['first_name'].hasError('required')">
        First Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <div fxLayout fxLayoutAlign="space-between" fxLayoutGap="30px">
      <mat-form-field class="full-width">
        <mat-label>Middle Name</mat-label>
        <input matInput formControlName="middle_name" />
        <mat-error *ngIf="form.controls['middle_name'].hasError('required')">
          Middle Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="last_name" />
        <mat-error *ngIf="form.controls['last_name'].hasError('required')">
          Last Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <!-- <mat-form-field fxFlex="15">
          <mat-label>Type</mat-label>
          <mat-select disableRipple formControlName="type">
            <mat-option value="1">Option 1</mat-option>
            <mat-option value="2">Option 2</mat-option>
            <mat-option value="3">Option 3</mat-option>
          </mat-select>
        </mat-form-field> -->
      <mat-form-field fxFlex="60">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required />
        <mat-error *ngIf="form.controls['email'].hasError('required')">
          Email is <strong>required</strong>
        </mat-error>

        <mat-error *ngIf="form.controls['email'].hasError('pattern')">
          Email Address is <strong>invalid</strong>
        </mat-error>
      </mat-form-field>
      <!-- <div>
                    <mat-icon class="cursor-point" svgIcon="icon-ico-plus"></mat-icon>
                </div> -->
    </div>

    <ng-container formArrayName="phone">
      <div
        [formGroupName]="i"
        fxLayout.gt-sm="row" fxLayout="column" fxLayout.sm="column"
        *ngFor="let phoneItem of phone?.controls; index as i"
        fxLayoutGap="20px"
        fxLayoutAlign.gt-sm="start center"
        fxLayoutAlign.sm="start start"
      >
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
        <mat-form-field fxFlex="40"  fxFlex.sm="35" fxFlex.xs="35">
          <mat-label>Type</mat-label>
          <mat-select disableRipple formControlName="type">
            <mat-option
              *ngFor="let type of applicationConstant?.phone_types"
              [value]="type.value"
            >
              {{ type.display_value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="phoneItem.get('type')?.hasError('required')">
            Phone Type is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="40" fxFlex.sm="35" fxFlex.xs="35">
          <mat-label>Country</mat-label>
          <mat-select disableRipple formControlName="code">
            <mat-option
              *ngFor="let item of applicationConstant?.country_codes"
              [value]="item.code"
            >
              ({{ item.calling_code }})-{{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="phoneItem.get('code')?.hasError('required')">
            Phone Type is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
        <mat-form-field fxFlex="40" fxFlex.sm="35" fxFlex.xs="35">
          <mat-label>Contact Number</mat-label>
          <input matInput formControlName="number" />
          <mat-error *ngIf="phoneItem.get('number')?.hasError('required')">
            Contact Number is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="phoneItem.get('number')?.hasError('digit')">
            Only digit is <strong>allowed</strong>
          </mat-error>
          <mat-error *ngIf="phoneItem.get('number')?.hasError('minLength')">
            Minimum digit are
            <strong>{{
              phoneItem.get("number")?.errors?.minLength?.refValues[1]
            }}</strong>
          </mat-error>
          <mat-error *ngIf="phoneItem.get('number')?.hasError('maxLength')">
            Maximum digit are
            <strong>{{
              phoneItem.get("number")?.errors?.maxLength?.refValues[1]
            }}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="40" fxFlex.sm="35" fxFlex.xs="35">
          <mat-label>Extension</mat-label>
          <input matInput formControlName="extension" />
          <!-- <mat-error *ngIf="phoneItem.get('extension')?.hasError('required')">
              Extension is <strong>required</strong>
            </mat-error> -->
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
        <div>
          <mat-icon
            (click)="addNewPhone()"
            class="cursor-point"
            svgIcon="icon-ico-plus"
          ></mat-icon>
        </div>

        <div>
          <mat-icon
            *ngIf="phone?.controls?.length! > 1"
            (click)="removePhone(i)"
            class="cursor-point"
            svgIcon="icon-ico-minus"
          ></mat-icon>
        </div>
      </div>
      </div>
    </ng-container>

    <!-- <div class="mt-1">
      <mat-checkbox class="example-margin" class="checkbox" formControlName="is_admin">
        Disable adminstrator access for the user
      </mat-checkbox>
    </div> -->
    <div fxLayout="row" fxLayoutGap="30px" class="action-button-group">
      <button
        mat-stroked-button
        color="warn"
        type="button"
        [routerLink]="'/admin/users'"
      >
        Cancel
      </button>
      <button mat-raised-button color="warn" type="submit">Save</button>
    </div>
  </form>
</div>
