<div class="section-header posR {{class[0]}}" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
    <div class="header-right-section {{class[1]}}">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px">
            <div fxLayout="column" class="label-gap">
                <label class="text-data">{{ labelText1 }}</label>
                <label class="value-data">{{ value1 }}</label>
            </div>
            <div fxLayout="column" class="label-gap">
                <label class="text-data">{{ labelText2 }}</label>
                <label class="value-data">{{ value2 }}</label>
            </div>
            <div fxLayout="column" class="label-gap">
                <label class="text-data">{{ labelText3 }}</label>
                <label class="value-data">{{ value3 }}</label>
            </div>
            <div fxLayout="column" class="label-gap">
                <label class="text-data">{{ labelText4 }}</label>
                <label class="value-data">{{ value4 }}</label>
            </div>
            <div fxLayout="column" class="label-gap">
                <label class="text-data">{{ labelText7 }}</label>
                <label class="value-data">{{ value7 }}</label>
            </div>
            <div fxLayout="column" class="label-gap">
                <label class="text-data">{{ labelText8 }}</label>
                <label class="value-data">{{ value8 }}</label>
            </div>
        </div>
    </div>
    <div class="action code action-code">
        <div class="button-section" *ngIf="isButtonShow">
            <button class="heading-button" mat-raised-button color="warn" (click)="goTo()">
                <ng-content select="#buttonLabel"></ng-content>
            </button>
        </div>
        <label *ngIf="isLabelShow">
            Action Code: 2001
        </label>
    </div>
    <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
       <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon class="header-icon">sentiment_satisfied_alt</mat-icon>
        </button>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon class="header-icon">sentiment_satisfied_alt</mat-icon>
        </button>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon class="header-icon material-icons-outlined">filter_alt</mat-icon>
        </button> 
        <div class="button-section" *ngIf="isButtonShow">
            <button class="heading-button" mat-raised-button color="warn" (click)="goTo()">
                <ng-content select="#buttonLabel"></ng-content>
            </button>
        </div>
    </div> -->
</div>